<template>
  <el-card>
    <!-- 查询筛选表单 -->
    <el-form :model="queryParams" ref="queryFormRef" inline>
      <el-form-item label="筛选" prop="queryString">
        <el-input
          size="mini"
          v-model="queryParams.queryString"
          placeholder="请输入关键字进行查询"
          style="width: 200px"
        />
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <el-select
          size="mini"
          v-model="queryParams.status"
          placeholder="请选择合同状态"
          clearable
        >
          <el-option label="等待付款" :value="0"></el-option>
          <el-option label="已生效" :value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          size="mini"
          icon="el-icon-search"
          @click="getList"
          >搜索</el-button
        >
        <el-button size="mini" icon="el-icon-refresh" @click="resetForm"
          >重置</el-button
        >
      </el-form-item>
    </el-form>

    <!-- 合同操作部分 -->
    <el-row>
      <el-col :span="1.5">
        <el-button
          plain
          size="mini"
          type="primary"
          icon="el-icon-plus"
          @click="addNewContractHandle"
          >新增合同</el-button
        >
      </el-col>
    </el-row>

    <!-- 合同数据列表 -->
    <el-table :data="list">
      <el-table-column type="expand">
        <template slot-scope="scope">
          <el-form label-position="left" inline class="demo-table-expand">
            <el-form-item label="商城用户">{{
              scope.row.userName
            }}</el-form-item>
            <el-form-item label="公司">{{ scope.row.company }}</el-form-item>
            <el-form-item label="法人">{{
              scope.row.legalPerson
            }}</el-form-item>
            <el-form-item label="地址">{{ scope.row.address }}</el-form-item>
            <el-form-item label="代理人">{{ scope.row.agent }}</el-form-item>
            <el-form-item label="代理人职位">{{
              scope.row.agentPositon
            }}</el-form-item>
            <el-form-item label="联系方式">{{ scope.row.phone }}</el-form-item>
            <el-form-item label="传真">{{ scope.row.fax }}</el-form-item>
            <el-form-item label="银行">{{ scope.row.bank }}</el-form-item>
            <el-form-item label="银行账号">{{
              scope.row.bankAcount
            }}</el-form-item>
            <el-form-item label="税号">{{ scope.row.dutyNumber }}</el-form-item>
            <el-form-item label="生效时间">{{
              scope.row.nowDateTime
            }}</el-form-item>
            <el-form-item label="开发天数">{{ scope.row.days }}天</el-form-item>
            <el-form-item label="商品名称">{{
              scope.row.goodsName
            }}</el-form-item>
            <el-form-item label="品牌">{{ scope.row.brand }}</el-form-item>
            <el-form-item label="型号">{{ scope.row.model }}</el-form-item>
            <el-form-item label="价格">{{ scope.row.price }}元</el-form-item>
            <el-form-item label="创建时间">{{
              scope.row.createTime
            }}</el-form-item>
            <template v-if="scope.row.status == 1">
              <el-form-item label="审核时间">{{
                scope.row.auditTime
              }}</el-form-item>
              <el-form-item label="合同状态">
                <el-tag effect="dark" type="info" v-if="scope.row.status == 0"
                  >等待付款</el-tag
                >
                <el-tag effect="dark" type="success" v-else
                  >已生效</el-tag
                ></el-form-item
              >
              <el-form-item label="审核备注">{{
                scope.row.auditRemark
              }}</el-form-item>
              <el-form-item label="凭证图片">
                <el-image
                  style="width: 100px; height: 100px"
                  :src="imageBaseUrl + scope.row.auditFile"
                  :preview-src-list="[imageBaseUrl + scope.row.auditFile]"
                  fit="contain"
                ></el-image>
              </el-form-item>
            </template>
          </el-form>
        </template>
      </el-table-column>

      <el-table-column type="index" label="#" width="40"></el-table-column>
      <!-- <el-table-column prop="id" label="Id" width="50"></el-table-column> -->
      <el-table-column
        prop="userName"
        label="商城用户"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="company"
        label="公司"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column prop="legalPerson" label="法人"></el-table-column>
      <el-table-column label="合同状态">
        <template slot-scope="scope">
          <el-tag effect="dark" type="info" v-if="scope.row.status == 0"
            >等待付款</el-tag
          >
          <el-tag effect="dark" type="success" v-else>已生效</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="goodsName"
        label="商品名称"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column prop="days" label="开发时间(天)"></el-table-column>
      <el-table-column prop="price" label="价格(元)"></el-table-column>
      <el-table-column
        prop="nowDateTime"
        label="生效时间"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="auditTime"
        label="审核时间"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            size="mini"
            v-if="scope.row.status == 0"
            type="text"
            icon="el-icon-edit"
            @click="editContractHandle(scope.row)"
            >编辑</el-button
          >
          <el-button
            size="mini"
            type="text"
            icon="el-icon-upload2"
            @click="openAuditDialogHandle(scope.row)"
            >上传凭证</el-button
          >
          <el-button
            size="mini"
            type="text"
            style="color: #67c23a"
            icon="el-icon-download"
            @click="downLoadFile(scope.row)"
            >下载</el-button
          >
          <el-button
            size="mini"
            v-if="scope.row.status == 0"
            type="text"
            style="color: #f56c6c"
            icon="el-icon-delete"
            @click="deleteItem(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 合同分页组件 -->
    <el-pagination
      style="margin-top: 20px; text-align: right"
      @size-change="getList"
      @current-change="getList"
      :current-page="queryParams.pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="queryParams.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>

    <!-- 新增/编辑合同 -->
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="formVisible"
      :title="title"
    >
      <el-form ref="contractFormRef" :model="form">
        <el-form-item v-if="form.id" label="Id">{{ form.id }}</el-form-item>

        <el-form-item label="商城用户">
          <el-select
            style="width: 100%"
            v-model="form.userId"
            filterable
            @focus="onFocus"
            remote
            placeholder="请输入用户名或者公司名"
            :remote-method="userRemoteFun"
          >
            <el-option
              v-for="item in userOption"
              :key="item.id"
              :label="item.userName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item label="公司">
              <el-input v-model="form.company" placeholder="请输入公司名称" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="法人">
              <el-input
                v-model="form.legalPerson"
                placeholder="请输入法人名称"
              />
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="地址">
              <el-input
                type="textarea"
                v-model="form.address"
                placeholder="请输入甲方公司地址"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="6">
            <el-form-item label="代理人">
              <el-input
                v-model="form.agent"
                placeholder="请输入代理人名称"
              /> </el-form-item
          ></el-col>
          <el-col :span="6">
            <el-form-item label="代理人职位">
              <el-input
                v-model="form.agentPositon"
                placeholder="请输入代理人职位"
              /> </el-form-item
          ></el-col>
          <el-col :span="6">
            <el-form-item label="电话">
              <el-input
                v-model="form.phone"
                placeholder="请输入甲方电话联系方式"
              /> </el-form-item
          ></el-col>
          <el-col :span="6">
            <el-form-item label="传真">
              <el-input
                v-model="form.fax"
                placeholder="请输入甲方传真联系方式"
              /> </el-form-item
          ></el-col>
        </el-row>

        <el-row :gutter="10">
          <el-col :span="8">
            <el-form-item label="开户行">
              <el-input
                v-model="form.bank"
                placeholder="请输入甲方开户行名称"
              /> </el-form-item
          ></el-col>
          <el-col :span="8">
            <el-form-item label="开户行账号">
              <el-input
                v-model="form.bankAcount"
                placeholder="请输入甲方开户行账号"
              /> </el-form-item
          ></el-col>
          <el-col :span="8">
            <el-form-item label="税号">
              <el-input
                v-model="form.dutyNumber"
                placeholder="请输入甲方税号"
              /> </el-form-item
          ></el-col>
        </el-row>

        <el-row :gutter="10">
          <el-col :span="8">
            <el-form-item label="商品名称">
              <el-input
                v-model="form.goodsName"
                placeholder="请输入商品名称"
              /> </el-form-item
          ></el-col>
          <el-col :span="8">
            <el-form-item label="商品品牌">
              <el-input
                v-model="form.brand"
                placeholder="请输入商品品牌"
              /> </el-form-item
          ></el-col>
          <el-col :span="8">
            <el-form-item label="商品型号">
              <el-input
                v-model="form.model"
                placeholder="请输入商品型号"
              /> </el-form-item
          ></el-col>
        </el-row>

        <el-form-item label="合同生效时间">
          <el-date-picker
            v-model="form.nowDateTime"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker
        ></el-form-item>

        <el-row :gutter="10">
          <el-col :span="8">
            <el-form-item label="开发天数">
              <el-input-number
                :precision="0"
                v-model="form.days"
                :min="1" /></el-form-item
          ></el-col>
          <el-col :span="8">
            <el-form-item label="合同价格">
              <el-input-number
                v-model="form.price"
                :precision="2"
                :step="1"
              ></el-input-number> </el-form-item
          ></el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="formVisible = false">取消</el-button>
        <el-button type="primary" @click="submitHandle">确定</el-button>
      </div>
    </el-dialog>

    <!-- 提交付款凭证 -->
    <el-dialog :visible.sync="auditVisible" title="提交审核凭证">
      <el-form label-width="80px">
        <el-form-item label="Id">{{ auditForm.id }}</el-form-item>
        <el-form-item label="审核备注">
          <el-input v-model="auditForm.remark" type="textarea" />
        </el-form-item>
        <el-form-item label="审核图片">
          <el-upload
            style="width: 100%"
            accept="image/jpeg,image/jpg,image/png"
            :limit="1"
            :on-change="changFile"
            :on-exceed="handleExceed"
            :file-list="fileList"
            :auto-upload="false"
            ref="upload"
            action=""
            list-type="picture-card"
          >
            <div slot="file" slot-scope="{ file }">
              <el-image
                class="el-upload-list__item-thumbnail"
                :src="file.url"
                style="width: 146px; height: 146px"
                fit="contain"
              ></el-image>
              <span class="el-upload-list__item-actions">
                <span
                  class="el-upload-list__item-preview"
                  @click="handlePictureCardPreview(file)"
                >
                  <i class="el-icon-zoom-in"></i>
                </span>
                <span
                  class="el-upload-list__item-delete"
                  @click="removeFile(file)"
                >
                  <i class="el-icon-delete"></i>
                </span>
              </span>
            </div>

            <i slot="default" class="el-icon-plus"></i>
            <div class="el-upload__tip" slot="tip">
              只能上传jpg/png文件，且不超过2M
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button size="smail" @click="auditVisible = false">取消</el-button>
        <el-button size="smail" type="primary" @click="auditSubmitHandle"
          >确认</el-button
        >
      </div>
    </el-dialog>

    <!-- 审核图片展示 -->
    <el-dialog append-to-body :visible.sync="imgeShowDialog">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </el-card>
</template>

<script>
import { getUserOptions, getUserAddressList } from '@/api/user.js'
import { iosOnFocus } from '@/utils/iosPlatform.js'

import {
  list,
  getById,
  add,
  update,
  deleteContract,
  downLoadDoc,
  upAuditFile,
} from '@/api/contract'
export default {
  data() {
    return {
      list: [],
      imageBaseUrl: process.env.VUE_APP_Img_URL,
      total: 0,
      queryParams: {
        queryString: '',
        pageIndex: 1,
        pageSize: 20,
        status: null,
      },
      formVisible: false,
      title: '新增合同',
      form: {
        id: undefined,
        userId: null,
        company: '',
        legalPerson: '',
        address: '',
        agent: '',
        agentPositon: '',
        phone: '',
        fax: '',
        bank: '',
        bankAcount: '',
        dutyNumber: '',
        nowDateTime: null,
        goodsName: '',
        days: 0,
        brand: '',
        model: '',
      },
      auditVisible: false,
      fileList: [],
      auditForm: {
        id: null,
        file: null,
        remark: '',
      },
      imgeShowDialog: false,
      dialogImageUrl: '',
      userOption: [],
    }
  },
  created() {
    this.getList()
  },
  methods: {
    resetForm() {
      this.$refs.queryFormRef.resetFields()
      this.queryParams.pageIndex = 1
      this.getList()
    },
    async getList() {
      let { data } = await list(this.queryParams)
      console.log(data)

      this.list = data.data
      this.total = data.total
    },
    // 新增合同
    addNewContractHandle() {
      this.form = {
        id: undefined,
        userId: null,
        company: '',
        legalPerson: '',
        address: '',
        agent: '',
        agentPositon: '',
        phone: '',
        fax: '',
        bank: '',
        bankAcount: '',
        dutyNumber: '',
        nowDateTime: null,
        goodsName: '',
        days: 1,
        brand: '',
        model: '',
        price: 0,
      }
      this.title = '新增合同'

      this.formVisible = true
    },
    async editContractHandle(row) {
      let { data } = await getById(row.id)
      console.log(data)

      this.userOption = [
        {
          id: data.userId,
          userName: data.userName,
        },
      ]

      this.form = data
      this.title = '编辑合同'
      this.formVisible = true
    },
    async downLoadFile(row) {
      await downLoadDoc(row.id)
    },
    async deleteItem(row) {
      this.$confirm('此操作将永久删除该合同, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          await deleteContract(row.id)
          this.getList()
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    // 提交合同
    async submitHandle() {
      if (this.form.id) {
        await update(this.form)
      } else {
        await add(this.form)
      }
      this.getList()
      this.formVisible = false
    },
    // 上传凭证
    openAuditDialogHandle(row) {
      this.fileList = []

      this.auditForm = {
        id: row.id,
        file: null,
        remark: '',
      }

      this.auditVisible = true
    },

    async userRemoteFun(key) {
      if (key !== '') {
        let { data } = await getUserOptions(key)
        this.userOption = data
      }
    },

    changFile(file, fileList) {
      const isImage =
        file.raw.type === 'image/jpeg' ||
        file.raw.type === 'image/png' ||
        file.raw.type === 'image/jpg'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isImage) {
        this.$message.error('上传文件只能是jpg,jpeg,png图片格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传文件大小不能超过 2M!')
      }

      if (isImage && isLt2M) {
        // 校验成功后，开始文件上传
        this.uploadlist(file, fileList)
      } else {
        if (this.fileList.length != 0) {
          this.fileList = this.fileList.filter((item) => {
            return item.uid != file.uid
          })
        } else {
          this.fileList = []
        }
        return false
      }
      //选择文件后，给fileList对象赋值
      //  this.fileList = fileList
      // this.$refs.upload_attach_item.validate();
    },
    // 文件上传
    uploadlist(files, fileList) {
      // 判断是否是初次上传，若不是则将数组进行拼接
      if (this.fileList) {
        this.fileList = this.fileList.concat(files)
      } else {
        this.fileList[0] = files
      }
    },
    // 删除文件
    removeFile(file, fileList) {
      this.fileList = this.fileList.filter((item) => {
        return item.uid != file.uid
      })
    },
    // 文件超出长度
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      )
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.imgeShowDialog = true
    },
    clearUpload(refName) {
      this.$refs[refName].clearFiles()
      this.fileList = []
    },
    async auditSubmitHandle() {
      if (this.fileList.length <= 0) {
        return this.$message.error('请至少上传一张图片')
      }

      let formData = new FormData()
      formData.append('id', this.auditForm.id)
      formData.append('remark', this.auditForm.remark)
      formData.append('file', this.fileList[0].raw)

      await upAuditFile(formData)

      this.getList()

      this.auditVisible = false
    },
    onFocus(event) {
      iosOnFocus.call(this,event);
    },
  },
}
</script>

<style lang="scss">
.demo-table-expand {
  font-size: 0;
  padding: 20px;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
</style>
