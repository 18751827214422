import request from '@/utils/request'

// 分页查询合同
export function list (data) {
  return request({
    url: 'api/Contract/list',
    method: 'get',
    params: data,
  })
}

// 根据Id查合同
export function getById (id) {
  return request({
    url: 'api/contract/getById',
    method: 'get',
    params: { id }
  })
}


// 新增合同
export function add (data) {
  return request({
    url: 'api/contract/add',
    method: 'post',
    data
  })
}

// 更新合同
export function update (data) {
  return request({
    url: 'api/contract/update',
    method: 'post',
    data
  })
}

// 删除合同
export function deleteContract (id) {
  return request({
    url: 'api/contract/delete',
    method: 'get',
    params: { id }
  })
}

// 下载合同文件
export function downLoadDoc (id) {
  return request({
    url: 'api/contract/DownLoadFile',
    method: 'get',
    params: { id },
    responseType: 'blob',
  })
}

// 上传凭证
export function upAuditFile (data) {
  return request({
    url: 'api/contract/UpAudit',
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data' },
    transformRequest: [
      function () {
        return data
      },
    ],
    data
  })
}

// 获取合同报表信息
export function reportInfo (data) {
  return request({
    url: 'api/contract/ReportInfo',
    method: 'post',
    data
  })
}
